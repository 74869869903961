.main_ticket h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1em;
}

.main_ticket h1 {
  font-weight: 600;
  font-size: 2em;
}

.main_ticket h2 {
    color: #ffffff;
    font-size: 23px;
    font-weight: 500;
}

.main_ticket h4, h5 {
  color: #f6f1f1;
  font-size: 0.8rem;
}

.main_ticket h6 {
  font-size: 1.6rem;
}

.main_ticket p {
  letter-spacing: 1px;
}

.main_ticket img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.main_ticket section, .view_ticket {
    display: flex;
    background-color: white;
  }
  .main_ticket section > div, .view_ticket > div {
    padding: 10px 10px;
  }
  @media (max-width: 500px) {
    .main_ticket section > div:first-child, .view_ticket > div:first-child {
      padding: 10px 10px 10px 0;
    }
  }
  
  .view_ticket > div {
    min-width: 65px;
  }
  
  .flight--general {
    text-align: center;
    align-items: center;
  }
  .flight--general div:first-of-type, .flight--general div:last-of-type {
    flex: 1 50%;
  }
  .flight--general div:first-child {
    padding: 10px 10px;
  }
  .flight--qrcode {
    justify-content: center;
    align-items: center;
    height: 30vh;
  }
  .flight--qrcode img {
    -o-object-fit: contain;
    object-fit: contain;
    font-family: "object-fit: contain";
    height: 100%;
  }
  
  .extra--meteo p:last-of-type {
    font-size: 0.8rem;
  }
  .extra--image {
    height: 100%;
    display: block;
    padding: 0;
  }
  .extra--image img {
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit: cover";
    max-height: 100%;
    min-width: 100%;
  }
  
  .view_ticket {
    background-color: #ff4500;
    color: #f9ebd2;
    justify-content: flex-end;
    align-items: center;
  }
  .view_ticket h1 {
    flex: 1 90%;
  }
  .view_ticket h5 {
    color: #f9ebd2;
  }
  .view_ticket > div {
    flex: 1 10%;
  }
  .view_ticket > div:first-of-type {
    padding-left: 0;
  }
  
  @media (max-width: 500px) {
    section, .view_ticket {
      padding: 10px 20px;
    }
  
    .view_ticket {
      flex-wrap: wrap;
    }
  
    .extra--image {
      min-height: inherit;
    }
  
    .flight--general {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
    }
    .flight--general div:first-of-type {
      text-align: center;
      padding-left: 0;
    }
    .flight--general div:last-of-type {
      text-align: center;
      padding-right: 0;
    }
  
    .extra--meteo {
      flex-direction: column;
    }
    .extra--meteo > div {
      width: 100%;
      padding-left: 0;
    }
    .extra--image img {
      height: auto;
      max-height: -webkit-fill-available;
    }
  }
  section, h1, .view_ticket div:first-of-type {
    padding: 10px 50px;
}
  @media (min-width: 501px) {
    section, h1, .view_ticket div:first-of-type {
      padding: 10px 50px;
  }
  
    .flight--general {
      padding-bottom: 0;
    }
  
    .flight--TimeInfo {
      padding-top: 0;
    }
  
    .flight--qrcode {
      padding: 0 10px;
    }
  
    .extra--meteo {
      justify-content: space-around;
    }
    .extra--meteo > div {
      max-width: 30%;
    }
    .extra--meteo > div:last-of-type {
      padding-right: 0;
    }
  }
  @media (min-width: 1024px) {
    .flight--TimeInfo {
      padding-top: 10px;
    }
  
    .flight--qrcode {
      padding: 0 10px;
    }
  }

  .view_ticket {
    border-radius: 10px 10px 0 0;
  }
  @media (max-width: 500px) {
    .view_ticket {
      border-radius: 0;
    }
  }
  
  .flight--qrcode {
    border-radius: 0 0 10px 0;
  }
  @media (max-width: 500px) {
    .flight--qrcode {
      border-radius: 0;
    }
  }
  .flight--general {
    border-radius: 0 0 0 10px;
  }
  
  .extra--meteo {
    border-radius: 10px;
  }
  .extra--image {
    display: flex;
  }
  @media (min-width: 501px) and (max-width: 1023px) {
    .flight--TimeInfo {
      border-radius: 0 0 0 10px;
    }
    .flight--general {
      border-radius: 0;
    }
    .flight--qrcode {
      justify-content: flex-end;
    }
  }
  @media (min-width: 501px) {

  
    .flight--qrcode {
      height: 100%;
      padding: 0;
    }
  
    .extra--image {
      min-height: 100vh;
    }
  }
  @supports (display: grid) {
    @media (min-width: 501px) {
      .flight--qrcode {
        height: initial;
      }
    }
  }
  @supports not (display: grid) {

  
    .view_ticket {
      border-radius: 0;
    }
    @media (max-width: 500px) {
      .view_ticket {
        border-radius: 0;
      }
    }
  
    .flight--qrcode {
      border-radius: 0;
    }
    @media (max-width: 500px) {
      .flight--qrcode {
        border-radius: 0;
      }
    }
    .flight--general {
      border-radius: 0;
    }
  
    .extra--meteo {
      border-radius: 0;
    }
    .extra--image {
      min-height: inherit;
      display: block;
    }
  
    @media (min-width: 501px) and (max-width: 1023px) {
      .flight--TimeInfo {
        border-radius: 0;
      }
      .flight--general {
        border-radius: 0;
      }
      .flight--qrcode {
        justify-content: center;
      }
    }
    @media (min-width: 501px) {
      .main_ticket {
        height: inherit;
      }
  
      .flight--qrcode {
        height: initial;
      }
  
      .extra--image {
        min-height: inherit;
      }
    }
  }
  .extra--meteo {
    z-index: 2;
  }
  @media (min-width: 501px) {
    .extra--meteo {
      justify-self: center;
    }
  }
  
  @media (max-width: 500px) {

  
    .view_ticket {
      grid-column: 1/span 2;
      grid-row: 1/span 1;
    }
  
    .flight--general, .flight--TimeInfo, .flight--PassInfo, .flight--qrcode {
      grid-column: 1/span 2;
    }
    .flight--general {
      grid-row: 2/span 1;
    }
    .flight--TimeInfo {
      grid-row: 3/span 1;
    }
    .flight--PassInfo {
      grid-row: 4/span 1;
    }
    .flight--qrcode {
      grid-row: 5/span 1;
    }
  
    .extra--meteo, .extra--image {
      grid-row: 6/span 1;
    }
    .extra--meteo {
      grid-column: 1/span 1;
    }
    .extra--image {
      grid-column: 2/span 1;
    }
  }
  @media (min-width: 501px) and (max-width: 1023px) {

  
    .view_ticket {
      grid-column: 2/span 3;
      grid-row: 2/span 1;
      z-index: 2;
    }
  
    .flight--PassInfo, .flight--qrcode {
      z-index: 2;
    }
    .flight--general {
      grid-column: 2/span 2;
      grid-row: 3/span 1;
      z-index: 3;
    }
    .flight--TimeInfo {
      grid-column: 2/span 2;
      grid-row: 5/span 1;
      z-index: 3;
    }
    .flight--PassInfo {
      grid-column: 2/span 2;
      grid-row: 4/span 1;
    }
    .flight--qrcode {
      grid-column: 4/span 1;
      grid-row: 3/span 3;
    }
  
    .extra--meteo {
      grid-column: 2/span 3;
      grid-row: 7/span 1;
    }
    .extra--image {
      grid-column: 1/span 7;
      grid-row: 1/span 8;
    }
  }
  @media (min-width: 1024px) {

  
    .view_ticket {
      grid-column: 2/span 5;
      grid-row: 2/span 1;
      z-index: 2;
    }
  
    .flight--general, .flight--TimeInfo, .flight--PassInfo, .flight--qrcode {
      z-index: 2;
    }
    .flight--general {
      grid-column: 2/span 2;
      grid-row: 3/span 2;
    }
    .flight--TimeInfo {
      grid-column: 4/span 2;
      grid-row: 3/span 1;
    }
    .flight--PassInfo {
      grid-column: 4/span 2;
      grid-row: 4/span 1;
    }
    .flight--qrcode {
      grid-column: 6/span 1;
      grid-row: 3/span 2;
    }
  
    .extra--meteo {
      grid-column: 2/span 5;
      grid-row: 6/span 1;
    }
    .extra--image {
      grid-column: 1/span 7;
      grid-row: 1/span 7;
      z-index: 1;
    }
  }
  section.flight--TimeInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
button.view_ticket {
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 0.5rem;
    border: 1px solid #0000002e;
    color: #2c3e50 !important;
    font-weight: 600;
}
a.view_ticket_flight {
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
  margin-left: 0.5rem;
  border: 1px solid #0000002e;
  color: #2c3e50 !important;
  font-weight: 600;
}
button.view_ticket:hover {
  background: #e40000;
  color: #fff !important;
  transition: 0.5s;
}
.flight_center_img {
    position: relative;
    width: 100%;
}
.flight_center_img:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #ececec3b;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
section.flight--TimeInfo p {
    font-size: 12px;
    color: #2c3e50;
}
.flight_center_img img {
    z-index: 9;
    position: relative;
}
.upcoming_trips .main_ticket {
  margin-bottom: 2rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px 1px #0000002e;
}
.upcoming_trips .main_ticket section.flight--general {
    background: #004e83;
}
.cancelled_trip_sec .main_ticket section.flight--general {
    background: #78000a;
}
.completed_booking .main_ticket section.flight--general {
    background: #143d00;
}
section.flight--general {
    border-radius: 0px 0px 20px 20px;
    padding: 0.5rem 0;
}
a.view_ticket_flight {
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  margin-left: 0.5rem;
  border: 1px solid #0000002e;
  color: #1d8ddc !important;
  font-weight: 600;
  text-transform: uppercase;
}
button.red {
  color: #e40000 !important;
}