@media only screen and (max-width: 1199px) {
    .margin_right {
        margin-right: 0;
    }

}

@media only screen and (max-width: 1100px) {
    .hotel_search .search_btn_banner {
        padding: 8px 30px !important;
    }
}

@media only screen and (max-width: 991px) {
.recent__bager.horizontal_bager_td {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column-reverse;
}
    .placebtn {
        padding: 5px 0px !important;
        font-size: 13px !important;
    }

    .user_menu_list.user_web_list {
        display: none;
    }

    .user_menu_list.user_mobile_list {
        display: block;
    }

    .submit_newsbtn_align {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }

    .desk_ce {
        margin: 0 2.5rem;
    }

    .makeStyles-column-29 {
        text-align: left;
    }

    .flight_image_grid {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .room_details_grid {
        margin-right: 30px;
    }

    button.book_tick {
        padding: 5px 15px !important;
    }

    .hotel_search .search_btn_banner {
        float: left;
    }

    nav.navbar {
        margin: 5px 0;
    }

    .mobileNone {
        display: none;
    }

    .dFlexStartTop {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .vector_image {
        bottom: 0;
    }

    .experienceSlide .owl-nav button.owl-next {
        right: -1rem;
    }

    .experienceSlide .owl-nav button.owl-prev {
        left: -1rem;
    }
}

@media only screen and (max-width: 959px) {
    .dis_none {
        display: none;
    }

    .mobile_flight_filter {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .date_txt {
        font-size: 12px !important;
    }
    .horizontal_bager_name h6 {
        font-size: 15px;
    }
    .verCard_title {
        font-size: 20px !important;
    }
    .verCard_content {
        font-size: 16px;
    }
    .blog_ver_card .horiCard_like_share h5 {
        font-size: 14px;
    }
    .horizontal_bager_name .css-1wlk0hk-MuiAvatar-root {
        width: 25px;
        height: 25px;
    }
    .blog_recentNews_banner {
        margin-bottom: 20px;
    }
    .bannercon {
        padding-top: 3rem;
        padding-bottom: 5rem;
    }

    .book_btm {
        display: block;
    }

    .mobile_view {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .travellerAddPop .form-group {
        width: 90%;
        margin: auto;
        padding-bottom: 20px;
    }

    .trip_price_slider {
        display: contents;
        position: relative;
        justify-content: center;
        text-align: center;
    }

    .room_details_grid {
        margin-right: 0;
        width: 45%;
    }

    .popular_hotel_card h6 span {
        padding-bottom: 10px;
    }

    .popular_hotel_card h6 {
        margin-bottom: 0;
    }

    .hotel_card_list_btn {
        position: unset;
        top: 0;
        transform: translateY(0);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .homesearch_box {
        position: absolute;
        top: 40%;
        left: 0;
        /* transform: translate(0); */
        z-index: 9;
        width: 100%;
    }

    .banner_sec .booking_select_tab {
        padding: 1rem;
    }

    .loginSlide.owl-theme .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        position: relative !important;
        padding-top: 0rem !important;
        left: 0;
        right: 0;
    }

    .trip-in {
        padding: 0;
    }

    .about-counter {
        display: block;
        padding-top: 2rem;
        text-align: center;
    }

    .about-counter .MuiBox-root {
        margin-bottom: 1.5rem;
    }

    .ourexperience_area {
        padding: 2rem 0 0rem 0;
    }

    .experienceSlide .owl-nav button.owl-next {
        right: 0;
    }

    .experienceSlide .owl-nav button.owl-prev {
        left: 0;
    }

    div#mfn {
        height: 100% !important;
        position: relative;
    }

    .banner_box.hotel_search {
        padding: 0rem;
    }

    .banner_box.hotel_search label {
        margin-bottom: 0.5rem !important;
        margin-top: 1rem;
    }

    .hotel_search .ant-picker {
        width: 100%;
    }

    .offer_slider_inner {
        display: grid;
        justify-content: stretch;
    }

    .item_experience_slider img {
        min-height: 250px;
        max-height: 250px;
        object-fit: cover;
    }

    img.testimonial_img {
        height: 30%;
        width: 15% !important;
    }

    .hotel_list_search .hotelSearch_grid.row {
        padding: 2.5rem;
    }

    .horizontalCardMain {
        display: table;
        width: 100%;
    }

    .hori_view_btn {
        display: block;
        width: 100%;
        padding: 0.5rem 0;
    }

    .booking_model_pop .popular_hotel_card h6 {
        font-size: 17px;
        margin-bottom: 0;
    }

    .booking_model_pop button.book_tick.pop_con {
        width: fit-content;
        margin-left: 0;
        padding: 0.5rem 2rem !important;
    }

    .flight_pop_contentent.MuiBox-root.css-i9gxme .flight_pop_total {
        width: 100%;
        float: initial;
    }

    .popular_hotel_card h3 {
        min-height: fit-content;
    }

    .hotel_confirm_card .card {
        width: 80%;
        height: 45vh;
    }

    .wrapper-traveller .right .info_data,
    .wrapper-traveller .right .projects_data {
        display: block;
        justify-content: center;
        align-items: center;
    }

    .wrapper-traveller .social_media ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    section.flight--TimeInfo .main_ticket h4,
    h5 br {
        display: none;
    }

    section.flight--TimeInfo {
        display: grid !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .main_ticket section,
    .view_ticket {
        display: block !important;
    }

    button.red {
        margin-top: 1rem;
        margin-left: 0 !important;
    }

    .booking_model_pop button.book_tick.pop_con {
        margin-left: 0;
    }

    .upcoming_trips .main_ticket {
        width: 100%;
    }

    .main_ticket section>div,
    .view_ticket>div {
        padding-left: 0;
    }

    .profile_pd {
        padding: 0rem 0;
        padding-bottom: 5rem;
    }

    .profileStyleModel {
        width: 85%;
    }

    .travellerAddPop {
        position: absolute;
        width: 80%;
        background: #fff;
        right: 0;
        left: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        height: 70vh;
        padding: 1rem;
        overflow-x: scroll;
    }

    .profile_tab_sec .css-heg063-MuiTabs-flexContainer {
        justify-content: center;
        display: flex;
        width: 100%;
    }

    .maillinkbtn a {
        padding-left: 0;
    }

    .guest_field {
        width: 100%;
        left: -2rem;
        right: 0;
    }

    .child_pass button {
        margin-top: 1rem;
    }

    .room_guest_btns button {
        width: 40%;
    }

    .session_timer {
        position: absolute;
        background: linear-gradient(to right, #2c3e50, #198ad8) !important;
        z-index: 9999999999999;
        width: -moz-fit-content;
        width: fit-content;
        padding: 3px 10px;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        left: -2rem;
        padding-left: 2rem;
    }

    .result_hotel_name_head {
        display: grid;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 2rem;
        text-align: center;
    }

    .result_hotel_location h6 {
        font-size: 12px;
        margin-top: 1rem;
    }

    .room_review_inner img {
        width: 15%;
        border-radius: 50%;
        margin-right: 1.5rem;
        position: absolute;
        top: -1rem;
        right: -2rem;
        background: #fff;
    }

    .room_review_inner {
        margin-bottom: 2rem;
    }

    .room_review {
        background: #f7f7f7;
        padding: 1rem;
    }

    .hotel_search_payment_chk {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .booking_values {
        margin-bottom: 1rem;
    }

    .yor_booking_con {
        text-align: center;
        z-index: 99;
    }

    .blog_user_profile_card {
        margin-bottom: 2rem;
    }

    .user_detail_area {
        margin-top: 0em;
        margin-bottom: 0em;
    }

    .user_detail_area {
        font-size: 10px;
    }

    main.main_ticket p {
        margin-bottom: 0;
    }

    main.main_ticket p {
        margin-bottom: 0;
        margin-top: 0;
    }

    section.flight--TimeInfo div {
        padding-left: 0;
    }

    span.sign_in_bt {
        font-size: 12px;
        padding-left: 1rem;
    }

    .popular_card {
        margin-bottom: 1.5rem;
    }

    .guest_room_list button {
        position: relative;
        right: 0px;
        bottom: 0.5rem;
    }

    .user_login_slider {
        width: 100%;
        position: relative;
        height: fit-content;
        display: inline-block;
        align-items: center;
        justify-content: center;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        text-align: center;
    }

    .user_login_slider img {
        margin: 3rem 0;
        max-height: 40px;
        min-height: 40px;
        margin-bottom: 1rem;
        margin-top: 4rem;
    }

    .loginSlide p {
        font-size: 12px;
        line-height: 20px;
    }

    .user_slider {
        padding-right: 0;
        width: 100%;
        overflow: hidden;
    }

    .user_slider {
        display: none;
    }

    .log_in {
        background: linear-gradient(to right, #0748ff4a, #198ad8) !important;
    }

    .sign_up {
        background: linear-gradient(to right, #0748ff4a, #198ad8) !important;
    }

    a.back_home.dn-m {
        display: block;
    }

    .log_in button.login_btn {
        margin-left: 0;
    }

    .user_ticket_tabs1 {
        display: block !important;
    }

    .user_ticket_tabs .css-1anid1y {
        position: inherit !important;
        display: inherit !important;
        flex: inherit !important;
        white-space: inherit !important;
        overflow-x: hidden !important;
        width: 100% !important;
        text-align: center !important;
    }

    .user_ticket_tabs .css-jpln7h-MuiTabs-scroller {
        position: inherit !important;
        display: inherit !important;
        flex: inherit !important;
        white-space: inherit !important;
        overflow-x: hidden !important;
        width: 100% !important;
        text-align: center !important;
    }

    .blog_user_personal_details .css-heg063-MuiTabs-flexContainer {
        display: block !important;
    }

    .popular_detail_page .css-k008qs {
        display: block !important;
    }

    .popular_detail_page .hotelSearch_grid.row {
        padding: 1.5rem;
    }

    .career_offered {
        margin-top: 3rem;
    }

    .contact_frm {
        margin-top: 3rem;
    }

    .contact {
        padding: 130px 0;
        padding-top: 60px;
        padding-bottom: 5rem;
    }

    .about-section .image-column .inner-column .image .overlay-box .year-box {
        font-size: 20px;
    }

    .about-section {
        padding-top: 1rem;
        padding-bottom: 4rem;
    }

    .left_foo_con_sec {
        margin: auto;
    }

    .flight_list_section .trip-in {
        padding: 1rem;
    }

    .confirm_ticket_pop .flightname {
        font-size: 12px;
    }

    .comlete_head ul li {
        float: inherit;
        margin-right: 0;
    }

    .payment_add_conatct_address input {
        margin-bottom: 0.5rem;
    }

    .flight_pop_contentent .card {
        margin: 1.5rem 0;
    }

    .offerSlide .owl-nav button.owl-prev {
        left: -1rem;
        width: 2rem;
        height: 2rem;
        box-shadow: 0px 0px 10px 0px #4e4e4e;
        font-size: 17px;
        line-height: 2;
    }

    .offerSlide .owl-nav button.owl-next {
        right: -1rem;
        width: 2rem;
        height: 2rem;
        ;
    }

    .subscribe_form {
        position: relative;
        width: 100%;
    }

    /* .blog_user_personal_details .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
        display: block !important;
        margin: auto !important;
    } */

    .hotel_search_submit {
        text-align: center;
    }

    .navbar .nav-toggle {
        z-index: 999999;
    }

    .cancel_trip_page a {
        padding: 0.3rem 1rem;
        margin: 0 1rem;
        font-size: 11px;
        letter-spacing: 0px;
    }

    .subscribe_con {
        font-size: 17px !important;
        padding-right: 0;
    }

    .submit_newsbtn_align {
        justify-content: center;
    }
}

@media only screen and (max-width: 575px) {
    .subNewsLetter {
        display: flex;
        flex-direction: column;
    }

    .pament_btn_sec {
        text-align: center;
    }

    .makeStyles-subNewsLetter-47 {
        display: flex;
        flex-direction: column;
    }

    .hotel-search_form {
        padding: 0 20px;
    }

    .popular_hotel_card h3 {
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 0;
    }

    .popular_hotel_card h6 span {
        padding-bottom: 0;
    }

    .hotel_card_contentbox h6.MuiTypography-root.MuiTypography-h6 {
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: 600;
        padding-bottom: 10px;
    }

    .bannercon {
        padding-left: 20px;
    }

    .popular-description {
        font-size: 18px;
    }

    .makeStyles-subNewsLetter-45 {
        flex-direction: column;
    }

    .makeStyles-subNewsLetter-23 {
        flex-direction: column;
    }

    .pop_total_card {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 558px) {

    .flight_image_grid {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .makeStyles-column-29 {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .destinamtion_flight {
        width: 100%;
        padding: 10px 0;
    }
}

@media only screen and (max-width: 520px) {
    .hotelBanner {
        height: 35vh;
    }

    section,
    h1,
    .view_ticket div:first-of-type {
        padding: 10px 20px;
    }
}

@media only screen and (max-width: 480px) {
    img.brandlogo {
        width: 150px;
    }

    span.sign_in_bt {
        font-size: 9px;
    }
}

@media only screen and (max-width: 420px) {



    .hotel_list_search .hotelSearch_grid.row {
        padding: 0.5rem;
    }

    .hotelBanner {
        height: 30vh;
    }

    .popular_hotel_card h3 {
        font-size: 15px;
    }

    .popular_hotel_card h6 {
        font-size: 16px;
    }

    h6.hotel_btn {
        font-size: 13px;
    }

    .makeStyles-subNewsLetter-40 {
        align-items: flex-start;
        flex-direction: column !important;
    }

    .makeStyles-column-29 {
        flex-basis: 100% !important;
        display: flex !important;
        align-items: flex-start;
        justify-content: space-around !important;
        flex-direction: column;
    }

    .flight_image_grid {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 390px) {
    img.brandlogo {
        width: 120px;
    }

    span.sign_in_bt {
        font-size: 9px;
    }

    .user_menu button {
        padding: 0px 5px;
    }

    .user_menu span.pr-2 {
        font-size: 9px;
    }

    .bannercon {
        padding-top: 2rem;
        padding-bottom: 5rem;
    }

    .user_menu {
        margin-left: 0;
    }

    span.sign_in_bt {
        font-size: 10px;
    }

    .header-divider {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a.makeStyles-designname-44 {
        font-size: 11px !important;
    }

    .subscribe_btn {
        padding: 10px 15px !important;
        height: 45px;
    }

    .subscribe_form input {
        padding: 0;
        font-size: 15px;
    }

    .hotel_search_payment_chk div {
        padding: 0.5rem;
        margin-bottom: 5px;
    }

    .room_details_grid {
        margin-right: 0;
        width: 50%;
    }

    .hotel_review_inner {
        padding: 5px 5px;
    }

    .makeStyles-subscrib-48 {
        padding: 1rem 1rem;
    }

    .hotel-search_form .banner_box.hotel_search {
        padding: 3rem 0.5rem;
    }

    .hotel-search_form {
        padding: 0 10px;
    }

    .popular_hotel_card h6.MuiTypography-root.MuiTypography-h6 {
        display: block;
    }

    .popular_hotel_card h6 span {
        margin-left: 0;
    }

    .popular_hotel_card h3 {
        line-height: 20px;
        padding-bottom: 10px;
    }

    .hotel_btn {
        padding: 6px 14px !important;
    }

    .popular_hotel_card h6 {
        font-size: 14px;
    }

    .popular_hotel_card h6.MuiTypography-root.MuiTypography-h6 {
        font-size: 15px;
    }

    .popular_hotel_card h6 span {
        font-size: 10px;
    }

    .hotel_list_page .popular_hotel_card h6 span {
        display: none;
    }

    p.daysTripContent {
        font-size: 14px;
        line-height: 22px;
    }

    .bannercon_hotel {
        padding: 0 10px;
    }

    .open_flight_list {
        font-size: 11px;
        padding: 5px 10px !important;
        height: 30px;
    }
}

@media only screen and (max-width: 325px) {
    span.sign_in_bt {
        font-size: 8px;
    }

    .makeStyles-brandlogo-3 {
        max-height: 35px !important;
        min-height: 35px !important;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.nav_Icons.css-i4bv87-MuiSvgIcon-root {
        font-size: 33px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .banner_box.hotel_search {
        padding: 1rem;
    }

    .flight_pop_contentent .card {
        width: 100% !important;
    }

    .single_persion_con h4 {
        font-size: 17px;
    }

    .single_persion_con ul li {
        font-size: 11px;
    }

    button.view_ticket_flight {
        border: 1px solid;
        padding: 3px 10px;
        font-size: 13px;
        font-weight: 500;
        color: #1d8ddc;
        margin: 0 0 0.5rem 0.5rem;
        width: 100%;
    }

    button.red {
        color: #e40000 !important;
        width: 100%;
    }

    .booking_model_pop button.book_tick.pop_con {
        width: 100% !important;
        font-size: 12px;
        margin-left: 0;
    }

    .offer_slider_inner {
        display: grid;
        justify-content: stretch;
    }

    img.testimonial_img {
        height: 20%;
        width: 28% !important;
    }

    .experience_slider_content h3 {
        font-size: 16px;
    }

    .item_experience_slider img {
        min-height: 250px;
        max-height: 250px;
        object-fit: cover;
    }

    .about-section .content-column .inner-column {
        padding-top: 0;
        padding-right: 0;
    }

    .about-section {
        position: relative;
        padding: 60px 0px;
    }

    .about-section .image-column .inner-column .image .overlay-box .year-box {
        font-size: 24px;
    }

    .left_foo_con_sec {
        width: 100%;
    }

    .footer_contact_form_sec {
        padding: 50px 0px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .booking_model_pop button.book_tick.pop_con {
        width: 100% !important;
        font-size: 13px;
        margin-left: 0;
    }
}

@media only screen and (min-width: 450px) and (max-width: 768px) {}